import { AiOutlineHtml5 as HTML } from "react-icons/ai";
import { BiLogoCss3 as CSS } from "react-icons/bi";
import { TbBrandJavascript as JS } from "react-icons/tb";
import { FaReact as React } from "react-icons/fa";
import { FaNodeJs as NodeJs } from "react-icons/fa";
import { SiExpress as ExpressJS } from "react-icons/si";
import { AiOutlinePython as Python } from "react-icons/ai";
import { SiMongodb as MongoDb } from "react-icons/si";
import { SiPostgresql as Postgres } from "react-icons/si";
import { PiFileSqlThin as Sql } from "react-icons/pi";
import { FaPhp as PHP } from "react-icons/fa6";
import { FaGitSquare as Git } from "react-icons/fa";
import { FaGithub as GitHub } from "react-icons/fa";
import { DiJqueryLogo as JQuery } from "react-icons/di";
import { FaBootstrap as BootStrap } from "react-icons/fa6";
import { BiLogoSass as SASS } from "react-icons/bi";
import { GrMysql as MySql } from "react-icons/gr";
import { SiRedux as Redux } from "react-icons/si";


export const skills = [
    {
        name: "HTML",
        icon: <HTML className="icon" />,
    },
    {
        name: "CSS",
        icon: <CSS className="icon" />,
    },
    {
        name: "SASS",
        icon: <SASS className="icon" />,
    },
    {
        name: "BootStrap",
        icon: <BootStrap className="icon" />,
    },
    {
        name: "JavaScript",
        icon: <JS className="icon" />,
    },
    {
        name: "jQuery",
        icon: <JQuery className="icon" />,
    },
    {
        name: "React",
        icon: <React className="icon" />,
    },
    {
        name: "Redux",
        icon: <Redux className="icon" />,
    },
    {
        name: "NodeJS",
        icon: <NodeJs className="icon" />,
    },
    {
        name: "ExpressJS",
        icon: <ExpressJS className="icon" />,
    },
    {
        name: "Python",
        icon: <Python className="icon" />,
    },
    {
        name: "MongoDB",
        icon: <MongoDb className="icon" />,
    },
    {
        name: "Postgres",
        icon: <Postgres className="icon" />,
    },
    {
        name: "MySql",
        icon: <MySql className="icon" />,
    },
    {
        name: "PHP",
        icon: <PHP className="icon" />,
    },
    {
        name: "Git",
        icon: <Git className="icon" />,
    },
    {
        name: "GitHub",
        icon: <GitHub className="icon" />,
    },
];